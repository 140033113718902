<template>
  <div>
    <loading v-if="loading" />
    <v-card v-else :outlined="$vuetify.theme.dark">
      <v-card-text v-if="case_details">
        <!-- title -->
        <div class="main-title">
          <v-btn icon class="mr-2" @click="goBack">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <span>Edit Case</span>
        </div>

        <!-- form -->
        <v-form class="py-2" lazy-validation ref="form">
          <v-card outlined rounded="lg" class="mt-8">
            <div class="section-name pa-5">Client Information</div>

            <div class="mx-5 d-flex flex-wrap justify-space-between">
              <!-- client -->
              <v-sheet width="32%">
                {{ case_details.client.name }}
                <div>
                  <span v-if="case_details.client.user.mobile">
                    {{ case_details.client.user.mobile_display }} |
                  </span>
                  {{ case_details.client.user.email }}
                </div>
              </v-sheet>

              <v-sheet width="32%">
                <v-text-field
                  v-model="form.student_name"
                  type="text"
                  :rules="[]"
                  dense
                  outlined
                  hide-spin-buttons
                  label="Student Name"
                  class="rounded-lg"
                ></v-text-field>
              </v-sheet>

              <v-sheet width="32%">
                <v-text-field
                  v-model="form.student_email"
                  type="email"
                  :rules="[$rules.email]"
                  dense
                  outlined
                  hide-spin-buttons
                  label="Student Email"
                  class="rounded-lg"
                ></v-text-field
              ></v-sheet>
            </div>

            <v-divider />

            <div class="section-name pa-5">Basic Information</div>
            <div class="d-flex flex-wrap mx-5" style="gap: 0px 20px">
              <!-- service_type -->
              <v-sheet width="32%">
                <v-select
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                  append-icon="mdi-chevron-down"
                  :rules="[$rules.required]"
                  v-model="form.service_type"
                  :items="service_types"
                  item-text="value"
                  :disabled="true"
                  item-value="key"
                  return-object
                  flat
                  outlined
                  dense
                  label="Service Type"
                  class="rounded-lg"
                ></v-select>
              </v-sheet>

              <!-- service -->
              <v-sheet width="32%">
                <v-autocomplete
                  auto-select-first
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                  append-icon="mdi-chevron-down"
                  :rules="[$rules.required]"
                  v-model="form.service"
                  :items="service_list"
                  :disabled="isConverted"
                  item-text="name"
                  item-value="id"
                  return-object
                  flat
                  outlined
                  dense
                  label="Select Service"
                  class="rounded-lg"
                ></v-autocomplete>
              </v-sheet>

              <!-- state -->
              <v-sheet width="32%" v-if="hasPermission($keys.STATE)">
                <v-autocomplete
                  auto-select-first
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                  append-icon="mdi-chevron-down"
                  v-model="form.state"
                  @change="onStateChange"
                  :items="state_list"
                  :search-input.sync="state_search"
                  :loading="state_list_loading"
                  :rules="getFieldRules($keys.STATE)"
                  item-text="name"
                  item-value="id"
                  flat
                  outlined
                  dense
                  no-filter
                  return-object
                  :label="getFieldLabel($keys.STATE)"
                  class="rounded-lg"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="data.item.country">
                        {{ data.item.country.name }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-sheet>

              <!-- city -->
              <v-sheet width="32%" v-if="hasPermission($keys.CITY)">
                <v-autocomplete
                  auto-select-first
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                  append-icon="mdi-chevron-down"
                  v-model="form.city"
                  :items="city_list"
                  :search-input.sync="city_search"
                  :loading="city_list_loading"
                  :rules="getFieldRules($keys.CITY)"
                  item-text="name"
                  item-value="id"
                  flat
                  outlined
                  dense
                  no-filter
                  return-object
                  :label="getFieldLabel($keys.CITY)"
                  class="rounded-lg"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="data.item.state">
                        {{ data.item.state.name }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-sheet>

              <!-- university -->
              <v-sheet width="32%" v-if="hasPermission($keys.UNIVERSITY)">
                <v-autocomplete
                  auto-select-first
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                  :rules="getFieldRules($keys.UNIVERSITY)"
                  append-icon="mdi-chevron-down"
                  v-model="form.university"
                  :items="university_list"
                  :search-input.sync="university_search"
                  :loading="university_list_loading"
                  @change="onUniversityChange"
                  item-text="name"
                  item-value="id"
                  flat
                  outlined
                  dense
                  no-filter
                  :label="getFieldLabel($keys.UNIVERSITY)"
                  class="rounded-lg"
                >
                  <template v-slot:no-data>
                    <div class="mx-3 my-1 text-caption">
                      <span v-if="university_list_loading">Searching...</span>
                      <span v-else> No data available </span>
                    </div>
                  </template>
                </v-autocomplete>
              </v-sheet>

              <!-- education -->
              <v-sheet width="32%" v-if="hasPermission($keys.EDUCATION)">
                <v-autocomplete
                  auto-select-first
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                  :rules="getFieldRules($keys.EDUCATION)"
                  append-icon="mdi-chevron-down"
                  v-model="form.education"
                  :items="education_list"
                  item-text="name"
                  item-value="id"
                  flat
                  outlined
                  dense
                  :label="getFieldLabel($keys.EDUCATION)"
                  class="rounded-lg"
                ></v-autocomplete>
              </v-sheet>

              <!-- course -->
              <v-sheet width="32%" v-if="hasPermission($keys.COURSE)">
                <v-autocomplete
                  auto-select-first
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                  :rules="getFieldRules($keys.COURSE)"
                  append-icon="mdi-chevron-down"
                  v-model="form.course"
                  :items="course_list"
                  item-text="name"
                  item-value="id"
                  flat
                  outlined
                  dense
                  :label="getFieldLabel($keys.COURSE)"
                  class="rounded-lg"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.name }}
                        <span v-if="data.item.abbreviation"
                          >({{ data.item.abbreviation }})</span
                        >
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-sheet>

              <!-- no. of sets -->
              <v-sheet width="32%" v-if="hasPermission($keys.NO_OF_SETS)">
                <v-text-field
                  type="number"
                  @keydown="$rules.blockInvalidChar"
                  :rules="getFieldRules($keys.NO_OF_SETS)"
                  @input="validateNegativeNumber($keys.NO_OF_SETS)"
                  v-model="form.no_of_sets"
                  :disabled="isConverted"
                  min="1"
                  flat
                  outlined
                  dense
                  hide-spin-buttons
                  :label="getFieldLabel($keys.NO_OF_SETS)"
                  class="rounded-lg"
                ></v-text-field>
              </v-sheet>

              <!-- no. of documents -->
              <v-sheet width="32%" v-if="hasPermission($keys.NO_OF_DOCUMENTS)">
                <v-text-field
                  type="number"
                  @keydown="$rules.blockInvalidChar"
                  :rules="getFieldRules($keys.NO_OF_DOCUMENTS)"
                  @input="validateNegativeNumber($keys.NO_OF_DOCUMENTS)"
                  v-model="form.no_of_documents"
                  :disabled="isConverted"
                  min="1"
                  flat
                  outlined
                  dense
                  hide-spin-buttons
                  :label="getFieldLabel($keys.NO_OF_DOCUMENTS)"
                  class="rounded-lg"
                ></v-text-field>
              </v-sheet>

              <!-- document type -->
              <v-sheet width="32%" v-if="hasPermission($keys.DOCUMENT_TYPE)">
                <v-select
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                  :rules="getFieldRules($keys.DOCUMENT_TYPE)"
                  append-icon="mdi-chevron-down"
                  v-model="form.document_type"
                  :items="$keys.DOCUMENT_TYPES"
                  item-text="label"
                  item-value="key"
                  flat
                  outlined
                  dense
                  :label="getFieldLabel($keys.DOCUMENT_TYPE)"
                  class="rounded-lg"
                ></v-select>
              </v-sheet>

              <!-- delivery type -->
              <v-sheet width="32%" v-if="hasPermission($keys.DELIVERY_TYPE)">
                <v-select
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                  :rules="getFieldRules($keys.DELIVERY_TYPE)"
                  append-icon="mdi-chevron-down"
                  v-model="form.delivery_type"
                  :items="$keys.DELIVERY_TYPES"
                  item-text="label"
                  item-value="key"
                  flat
                  outlined
                  dense
                  :label="getFieldLabel($keys.DELIVERY_TYPE)"
                  class="rounded-lg"
                ></v-select>
              </v-sheet>

              <!-- send to eca -->
              <v-sheet width="32%" v-if="hasPermission($keys.SEND_TO_ECA)">
                <v-checkbox
                  class="my-2"
                  v-model="form.send_to_eca"
                  :label="getFieldLabel($keys.SEND_TO_ECA)"
                  :disabled="true"
                ></v-checkbox>
              </v-sheet>

              <!-- eca -->
              <v-sheet
                width="32%"
                v-if="hasPermission($keys.ECA) || form.send_to_eca"
              >
                <v-autocomplete
                  auto-select-first
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                  append-icon="mdi-chevron-down"
                  :rules="getFieldRules($keys.ECA)"
                  v-model="form.eca"
                  :items="eca_body_list"
                  item-text="name"
                  item-value="id"
                  flat
                  outlined
                  dense
                  multiple
                  :label="getFieldLabel($keys.ECA)"
                  class="rounded-lg"
                ></v-autocomplete>
              </v-sheet>

              <!-- eca_reference_number -->
              <v-sheet
                width="32%"
                v-if="
                  hasPermission($keys.ECA_REFERENCE_NUMBER) || form.send_to_eca
                "
              >
                <v-text-field
                  type="text"
                  :rules="getFieldRules($keys.ECA_REFERENCE_NUMBER)"
                  v-model="form.eca_reference_number"
                  flat
                  outlined
                  dense
                  :label="getFieldLabel($keys.ECA_REFERENCE_NUMBER)"
                  class="rounded-lg"
                ></v-text-field>
              </v-sheet>
            </div>

            <v-divider v-if="!isConverted" />

            <!-- pricing -->
            <div
              class="section-name ma-5 d-flex align-center justify-space-between"
              v-if="!isConverted"
            >
              <div class="d-flex align-center" style="gap: 20px">
                <div>Pricing Information</div>
                <v-btn
                  small
                  text
                  outlined
                  class="text-capitalize"
                  @click="getDefaultPricing"
                >
                  <v-icon small left>mdi-reload</v-icon>Get default pricing
                </v-btn>
              </div>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon>
                    <v-icon>mdi-information-outline</v-icon>
                  </v-btn>
                </template>
                <span v-if="defaultPricing && defaultPricing.info">
                  {{ defaultPricing.info }}
                </span>
                <span v-else>Info not available</span>
              </v-tooltip>
            </div>

            <div
              class="d-flex flex-wrap mx-5"
              style="gap: 0px 20px"
              v-if="!isConverted"
            >
              <!-- university_cost -->
              <v-sheet width="32%" v-if="hasPermission($keys.UNIVERSITY_COST)">
                <v-text-field
                  v-model="form.university_cost"
                  @keydown="$rules.blockInvalidChar"
                  :rules="getFieldRules($keys.UNIVERSITY_COST)"
                  @input="validateNegativeNumber($keys.UNIVERSITY_COST)"
                  type="number"
                  flat
                  min="0"
                  outlined
                  dense
                  hide-spin-buttons
                  :label="getFieldLabel($keys.UNIVERSITY_COST)"
                  class="rounded-lg"
                ></v-text-field>
              </v-sheet>

              <!-- university_cost_per_document -->
              <v-sheet
                width="32%"
                v-if="hasPermission($keys.UNIVERSITY_COST_PER_DOCUMENT)"
              >
                <v-text-field
                  v-model="form.university_cost_per_document"
                  @keydown="$rules.blockInvalidChar"
                  :rules="getFieldRules($keys.UNIVERSITY_COST_PER_DOCUMENT)"
                  @input="
                    validateNegativeNumber($keys.UNIVERSITY_COST_PER_DOCUMENT)
                  "
                  type="number"
                  flat
                  outlined
                  dense
                  hide-spin-buttons
                  :label="getFieldLabel($keys.UNIVERSITY_COST_PER_DOCUMENT)"
                  class="rounded-lg"
                ></v-text-field>
              </v-sheet>

              <!-- university_additional_cost -->
              <v-sheet
                width="32%"
                v-if="hasPermission($keys.UNIVERSITY_ADDITIONAL_COST)"
              >
                <v-text-field
                  v-model="form.university_additional_cost"
                  @keydown="$rules.blockInvalidChar"
                  :rules="getFieldRules($keys.UNIVERSITY_ADDITIONAL_COST)"
                  @input="
                    validateNegativeNumber($keys.UNIVERSITY_ADDITIONAL_COST)
                  "
                  type="number"
                  flat
                  outlined
                  dense
                  hide-spin-buttons
                  :label="getFieldLabel($keys.UNIVERSITY_ADDITIONAL_COST)"
                  class="rounded-lg"
                ></v-text-field>
              </v-sheet>

              <!-- processing_cost -->
              <v-sheet width="32%" v-if="hasPermission($keys.PROCESSING_COST)">
                <v-text-field
                  v-model="form.processing_cost"
                  @keydown="$rules.blockInvalidChar"
                  :rules="getFieldRules($keys.PROCESSING_COST)"
                  @input="validateNegativeNumber($keys.PROCESSING_COST)"
                  type="number"
                  flat
                  outlined
                  dense
                  hide-spin-buttons
                  :label="getFieldLabel($keys.PROCESSING_COST)"
                  class="rounded-lg"
                ></v-text-field>
              </v-sheet>

              <!-- processing_cost_per_copy -->
              <v-sheet
                width="32%"
                v-if="hasPermission($keys.PROCESSING_COST_PER_COPY)"
              >
                <v-text-field
                  v-model="form.processing_cost_per_copy"
                  @keydown="$rules.blockInvalidChar"
                  :rules="getFieldRules($keys.PROCESSING_COST_PER_COPY)"
                  @input="
                    validateNegativeNumber($keys.PROCESSING_COST_PER_COPY)
                  "
                  type="number"
                  flat
                  outlined
                  dense
                  hide-spin-buttons
                  :label="getFieldLabel($keys.PROCESSING_COST_PER_COPY)"
                  class="rounded-lg"
                ></v-text-field>
              </v-sheet>

              <!-- processing_cost_per_document -->
              <v-sheet
                width="32%"
                v-if="hasPermission($keys.PROCESSING_COST_PER_DOCUMENT)"
              >
                <v-text-field
                  v-model="form.processing_cost_per_document"
                  @keydown="$rules.blockInvalidChar"
                  :rules="getFieldRules($keys.PROCESSING_COST_PER_DOCUMENT)"
                  @input="
                    validateNegativeNumber($keys.PROCESSING_COST_PER_DOCUMENT)
                  "
                  type="number"
                  flat
                  outlined
                  dense
                  hide-spin-buttons
                  :label="getFieldLabel($keys.PROCESSING_COST_PER_DOCUMENT)"
                  class="rounded-lg"
                ></v-text-field>
              </v-sheet>

              <!-- processing_additional_cost -->
              <v-sheet
                width="32%"
                v-if="hasPermission($keys.PROCESSING_ADDITIONAL_COST)"
              >
                <v-text-field
                  v-model="form.processing_additional_cost"
                  :rules="getFieldRules($keys.PROCESSING_ADDITIONAL_COST)"
                  @input="
                    validateNegativeNumber($keys.PROCESSING_ADDITIONAL_COST)
                  "
                  flat
                  outlined
                  dense
                  hide-spin-buttons
                  :label="getFieldLabel($keys.PROCESSING_ADDITIONAL_COST)"
                  class="rounded-lg"
                ></v-text-field>
              </v-sheet>

              <!-- shipping_cost -->
              <v-sheet width="32%" v-if="hasPermission($keys.SHIPPING_COST)">
                <v-text-field
                  v-model="form.shipping_cost"
                  @keydown="$rules.blockInvalidChar"
                  :rules="getFieldRules($keys.SHIPPING_COST)"
                  @input="validateNegativeNumber($keys.SHIPPING_COST)"
                  type="number"
                  flat
                  outlined
                  dense
                  hide-spin-buttons
                  :label="getFieldLabel($keys.SHIPPING_COST)"
                  class="rounded-lg"
                ></v-text-field>
              </v-sheet>

              <!-- gst_percent -->
              <v-sheet width="32%" v-if="hasPermission($keys.GST_PERCENT)">
                <v-text-field
                  v-model="form.gst_percent"
                  @keydown="$rules.blockInvalidChar"
                  :rules="getFieldRules($keys.GST_PERCENT)"
                  @input="validateNegativeNumber($keys.GST_PERCENT)"
                  type="number"
                  flat
                  outlined
                  dense
                  hide-spin-buttons
                  :label="getFieldLabel($keys.GST_PERCENT)"
                  class="rounded-lg"
                ></v-text-field>
              </v-sheet>

              <!-- discount_percent -->
              <v-sheet width="32%" v-if="hasPermission($keys.DISCOUNT_PERCENT)">
                <v-text-field
                  v-model="form.discount_percent"
                  @keydown="$rules.blockInvalidChar"
                  :rules="getFieldRules($keys.DISCOUNT_PERCENT)"
                  @input="validateNegativeNumber($keys.DISCOUNT_PERCENT)"
                  type="number"
                  flat
                  dense
                  outlined
                  hide-spin-buttons
                  :label="getFieldLabel($keys.DISCOUNT_PERCENT)"
                  class="rounded-lg"
                ></v-text-field>
              </v-sheet>
            </div>

            <!-- computed price -->
            <v-sheet
              outlined
              rounded="lg"
              class="d-flex align-center justify-end pa-3 ma-4"
              v-if="!isConverted"
            >
              <div
                class="text-right"
                v-if="hasPermission($keys.TOTAL_UNIVERSITY_COST)"
              >
                <div class="text--secondary">Total University Cost</div>
                <div class="text-capitalize">₹ {{ total_university_cost }}</div>
              </div>
              <v-divider
                vertical
                class="mx-4"
                v-if="hasPermission($keys.TOTAL_UNIVERSITY_COST)"
              ></v-divider>

              <div class="text-right">
                <div class="text--secondary">Total Processing Cost</div>
                <div class="text-capitalize">₹ {{ total_processing_cost }}</div>
              </div>
              <v-divider vertical class="mx-4"></v-divider>
              <div class="text-right">
                <div class="text--secondary">GST Amount</div>
                <div class="text-capitalize">₹ {{ gst_amount }}</div>
              </div>
              <v-divider vertical class="mx-4"></v-divider>
              <div class="text-right">
                <div class="text--secondary">Discount Amount</div>
                <div class="text-capitalize">₹ {{ discount_amount }}</div>
              </div>
              <v-divider vertical class="mx-4"></v-divider>
              <div class="text-right">
                <div class="text--secondary">Total Amount</div>
                <div class="text-capitalize">₹ {{ total_amount }}</div>
              </div>
            </v-sheet>

            <v-divider v-if="!isConverted" />

            <!-- tat -->
            <div v-if="!isConverted">
              <div class="section-name pa-5">TAT (turnaround time)</div>
              <div class="d-flex flex-wrap mx-5" style="gap: 0px 20px">
                <!-- client_tat_days -->
                <v-sheet width="32%">
                  <v-text-field
                    v-model="form.client_tat_days"
                    type="number"
                    dense
                    @keydown="$rules.blockInvalidChar"
                    :rules="getFieldRules($keys.CLIENT_TAT_DAYS)"
                    @input="validateNegativeNumber($keys.CLIENT_TAT_DAYS)"
                    outlined
                    hide-spin-buttons
                    label="Client TAT"
                    hint="In Days"
                    persistent-hint
                    class="rounded-lg"
                  ></v-text-field>
                </v-sheet>

                <!-- internal_tat_days -->
                <v-sheet width="32%">
                  <v-text-field
                    v-model="form.internal_tat_days"
                    type="number"
                    dense
                    @keydown="$rules.blockInvalidChar"
                    :rules="getFieldRules($keys.INTERNAL_TAT_DAYS)"
                    @input="validateNegativeNumber($keys.INTERNAL_TAT_DAYS)"
                    outlined
                    hide-spin-buttons
                    label="Internal TAT"
                    hint="In Days"
                    persistent-hint
                    class="rounded-lg"
                  ></v-text-field>
                </v-sheet>
              </div>
            </div>
          </v-card>

          <!-- remarks -->
          <div class="mt-6" v-if="!isConverted">
            <v-textarea
              outlined
              v-model="form.remarks"
              :rules="[$rules.required]"
              label="Internal Remarks"
              class="rounded-lg"
            >
            </v-textarea>
          </div>

          <!-- actions -->
          <div class="mt-4 d-flex justify-start" style="gap: 16px">
            <v-btn
              color="primary"
              class="text-capitalize px-10"
              height="48px"
              @click="submit('save')"
              :loading="save_btn"
            >
              <span>Save</span>
            </v-btn>
            <v-btn
              color="primary"
              class="text-capitalize"
              height="48px"
              outlined
              :loading="save_send_quotation_btn"
              @click="submit('save_send_quotation')"
              v-if="!isConverted"
            >
              <span>Save & Send Quotation</span>
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- send quotation dialog -->
    <send-quotation-dialog />
  </div>
</template>
<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

import SendQuotationDialog from "@/components/Dialogs/SendQuotationDialog";

export default {
  components: { SendQuotationDialog },
  data() {
    return {
      loading: false,

      service_list: [],
      education_list: [],
      course_list: [],

      university_list: [],
      university_search: "",
      university_list_loading: false,

      state_list: [],
      state_search: "",
      state_list_loading: false,

      city_list: [],
      city_search: "",
      city_list_loading: false,

      eca_body_list: [],
      case_details: null,

      save_send_quotation_btn: false,
      save_btn: false,

      get_ship_cost: 0,

      defaultPricing: null,

      form: {
        student_name: "",
        student_email: "",
        service_type: null,
        service: null,
        state: null,
        city: null,
        university: null,
        education: null,
        course: null,
        no_of_sets: 1,
        no_of_documents: 1,
        send_to_eca: false,
        eca: null,
        eca_reference_number: "",
        document_type: this.$keys.EDUCATIONAL,
        delivery_type: this.$keys.E_SHIPMENT,

        // pricing
        university_cost: 0,
        university_cost_per_document: 0,
        university_additional_cost: 0,
        processing_cost: 0,
        processing_cost_per_copy: 0,
        processing_cost_per_document: 0,
        processing_additional_cost: 0,
        shipping_cost: 0,
        gst_percent: 0,
        discount_percent: 0,

        // tat
        internal_tat_days: 0,
        client_tat_days: 0,

        remarks: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      service_types: "getServiceTypes",
    }),

    isConverted() {
      if (this.case_details) {
        return this.case_details.is_converted;
      }
      return false;
    },

    total_university_cost() {
      return (
        (Number(this.form.university_cost) +
          Number(this.form.university_cost_per_document) *
            Number(this.form.no_of_documents)) *
          Number(this.form.no_of_sets) +
        Number(this.form.university_additional_cost)
      );
    },

    total_processing_cost() {
      return (
        Number(this.form.processing_cost) +
        Number(this.form.processing_cost_per_copy) *
          (Number(this.form.no_of_sets) - 1) +
        Number(this.form.processing_cost_per_document) *
          Number(this.form.no_of_documents) +
        Number(this.form.processing_additional_cost)
      );
    },

    gst_amount() {
      return (
        (Number(
          this.total_processing_cost + this.form.university_additional_cost
        ) *
          Number(this.form.gst_percent)) /
        100
      );
    },

    discount_amount() {
      return (
        (Number(this.form.processing_cost) *
          Number(this.form.discount_percent)) /
        100
      );
    },

    investment_cost() {
      return (
        Number(this.total_university_cost) +
        Number(this.total_processing_cost) +
        Number(this.form.shipping_cost)
      );
    },

    total_amount() {
      return (
        Number(this.investment_cost) +
        Number(this.gst_amount) -
        Number(this.discount_amount)
      );
    },
  },

  watch: {
    university_search: _.debounce(function (query) {
      if (this.university_list_loading) return;

      if (query && !this.form.university) {
        this.getUniversityList();
      }
    }, 1000),

    state_search: _.debounce(function (query) {
      if (this.state_list_loading) return;

      if (query && !this.form.state) {
        this.getStateList();
      }
    }, 1000),

    city_search: _.debounce(function (query) {
      if (this.city_list_loading) return;

      if (query && !this.form.city) {
        this.getCityList();
      }
    }, 1000),
  },

  async mounted() {
    this.loading = true;

    await this.getCaseDetails();
    await this.getServiceList();
    await this.getEducationList();
    await this.getCourseList();
    await this.getEcaBodyList();

    this.loading = false;
  },

  methods: {
    ...mapActions({
      showSnackbar: "app/showSnackbar",
      toggleSendQuotationDialog: "dialogs/toggleSendQuotationDialog",
      toggleAddEditStateDialog: "stateManagement/toggleDialog",
    }),

    hasPermission(field) {
      const service_type = this.form.service_type;

      if (service_type) {
        const _field = this.$_.find(service_type.form_fields, { key: field });
        return _field ? _field.show : false;
      }

      return false;
    },

    getFieldLabel(field) {
      const service_type = this.form.service_type;

      if (service_type) {
        const _field = this.$_.find(service_type.form_fields, { key: field });
        return _field ? _field.label : false;
      }

      return "";
    },

    getFieldRequired(field) {
      const service_type = this.form.service_type;

      if (service_type) {
        const _field = this.$_.find(service_type.form_fields, { key: field });
        return _field ? _field.required : false;
      }

      return false;
    },

    getFieldRules(field) {
      const _rules = [];

      if (this.getFieldRequired(field)) {
        _rules.push(this.$rules.required);
      }

      if (
        [
          this.$keys.NO_OF_SETS,
          this.$keys.NO_OF_DOCUMENTS,
          this.$keys.UNIVERSITY_COST,
          this.$keys.UNIVERSITY_COST_PER_DOCUMENT,
          this.$keys.UNIVERSITY_ADDITIONAL_COST,
          this.$keys.PROCESSING_COST,
          this.$keys.PROCESSING_COST_PER_COPY,
          this.$keys.PROCESSING_COST_PER_DOCUMENT,
          this.$keys.PROCESSING_ADDITIONAL_COST,
          this.$keys.SHIPPING_COST,
          this.$keys.GST_PERCENT,
          this.$keys.DISCOUNT_PERCENT,

          this.$keys.CLIENT_TAT_DAYS,
          this.$keys.INTERNAL_TAT_DAYS,
        ].includes(field)
      ) {
        _rules.push(this.$rules.validateNegativeNumber);
      }

      return _rules;
    },

    validateNegativeNumber(field) {
      let value = this.form[field];
      value = Number(value);

      if (value < 0) {
        this.form[field] = Number(0);
      } else this.form[field] = value;
    },

    goBack() {
      this.$router.go(-1);
    },

    getServiceList() {
      const onSuccess = (res) => {
        this.service_list = res.data.data;
      };

      let params = {};
      params["service_type"] = this.form.service_type.key;

      return this.$request(this.$keys.GET, this.$urls.master.service.list, {
        params,
        onSuccess,
      });
    },

    getStateList() {
      this.state_list_loading = true;

      const params = {
        apply_pagination: false,
        search: this.state_search,
      };

      const onSuccess = (res) => {
        this.state_list = res.data.data;
      };

      const onFinally = () => {
        this.state_list_loading = false;
      };

      return this.$request(this.$keys.GET, this.$urls.master.state.list, {
        params,
        onSuccess,
        onFinally,
      });
    },

    getCityList() {
      this.city_list_loading = true;

      const params = {
        apply_pagination: false,
        search: this.city_search,
      };

      if (this.form.state) {
        params["state_id"] = this.form.state.id;
      }

      const onSuccess = (res) => {
        this.city_list = res.data.data;
      };

      const onFinally = () => {
        this.city_list_loading = false;
      };

      return this.$request(this.$keys.GET, this.$urls.master.city.list, {
        params,
        onSuccess,
        onFinally,
      });
    },

    getEducationList() {
      const onSuccess = (res) => {
        this.education_list = res.data.data;
      };

      return this.$request(this.$keys.GET, this.$urls.master.education.list, {
        onSuccess,
      });
    },

    getCourseList() {
      const onSuccess = (res) => {
        this.course_list = res.data.data;
      };

      return this.$request(this.$keys.GET, this.$urls.master.course.list, {
        onSuccess,
      });
    },

    getUniversityList() {
      this.university_list_loading = true;

      const onSuccess = (res) => {
        this.university_list = res.data.data;
      };

      const onFinally = () => {
        this.university_list_loading = false;
      };

      const params = {
        search: this.university_search,
      };

      if (this.form.state) {
        params["state_id"] = this.form.state.id;
      }

      return this.$request(this.$keys.GET, this.$urls.university.list, {
        params,
        onSuccess,
        onFinally,
      });
    },

    getEcaBodyList() {
      const onSuccess = (res) => {
        this.eca_body_list = res.data.data;
      };

      const params = {
        apply_pagination: false,
      };

      return this.$request(this.$keys.GET, this.$urls.eca.list, {
        params,
        onSuccess,
      });
    },

    getDefaultPricing() {
      const onSuccess = (res) => {
        const data = res.data.data;
        this.defaultPricing = data;
        this.form.university_cost = data.university_cost;
        this.form.university_cost_per_document =
          data.university_cost_per_document;
        this.form.university_additional_cost = data.university_additional_cost;
        this.form.processing_cost = data.processing_cost;
        this.form.processing_cost_per_copy = data.processing_cost_per_copy;
        this.form.processing_cost_per_document =
          data.processing_cost_per_document;
        this.form.gst_percent = data.gst_percent;
        this.form.internal_tat_days = data.internal_tat_days;
        this.form.client_tat_days = data.client_tat_days;
      };

      const params = {
        service_id: this.form.service ? this.form.service.id : null,
        university_id: this.form.university,
      };

      return this.$request(this.$keys.GET, this.$urls.case.defaultPricing, {
        params,
        onSuccess,
      });
    },

    submit(action) {
      if (this.$refs.form.validate()) {
        switch (action) {
          case "save_send_quotation":
            this.save_send_quotation_btn = true;
            break;
          default:
            this.save_btn = true;
        }

        const onSuccess = (res) => {
          this.showSnackbar({
            text: res.data.message,
            color: "success",
          });
          switch (action) {
            case "save_send_quotation": {
              this.$router.push({
                name: "caseDetail",
                params: { id: res.data.data.id },
              });
              this.toggleSendQuotationDialog({
                show: true,
                caseId: res.data.data.id,
                clientId: res.data.data.client.id,
              });
              break;
            }
            default:
              this.$router.push({
                name: "caseDetail",
                params: { id: res.data.data.id },
              });
          }
        };

        const onFinally = () => {
          this.save_btn = false;
          this.save_send_quotation_btn = false;
        };

        const params = {
          case_id: this.$route.params.id,
        };

        const formData = {};

        // basic info
        formData["student_name"] = this.form.student_name;
        formData["student_email"] = this.form.student_email;

        formData["service"] = this.form.service.id;

        if (this.form.city) formData["city"] = this.form.city.id;
        if (this.form.state) formData["state"] = this.form.state.id;

        formData["education"] = this.form.education;
        formData["course"] = this.form.course;
        formData["no_of_sets"] = this.form.no_of_sets;
        formData["no_of_documents"] = this.form.no_of_documents;
        formData["eca"] = this.form.eca;
        formData["eca_reference_number"] = this.form.eca_reference_number;
        formData["document_type"] = this.form.document_type;
        formData["delivery_type"] = this.form.delivery_type;

        if (!this.isConverted) {
          // pricing
          formData["university_cost"] = this.form.university_cost;
          formData["university_cost_per_document"] =
            this.form.university_cost_per_document;
          formData["university_additional_cost"] =
            this.form.university_additional_cost;
          formData["processing_cost"] = this.form.processing_cost;
          formData["processing_cost_per_copy"] =
            this.form.processing_cost_per_copy;
          formData["processing_cost_per_document"] =
            this.form.processing_cost_per_document;
          formData["processing_additional_cost"] =
            this.form.processing_additional_cost;
          formData["shipping_cost"] = this.form.shipping_cost;
          formData["gst_percent"] = this.form.gst_percent;
          formData["discount_percent"] = this.form.discount_percent;

          // tat
          formData["client_tat_days"] = this.form.client_tat_days;
          formData["internal_tat_days"] = this.form.internal_tat_days;

          formData["remarks"] = this.form.remarks;
        }

        return this.$request(this.$keys.PATCH, this.$urls.case.update, {
          params,
          data: formData,
          onSuccess,
          onFinally,
        });
      }
    },

    getCaseDetails() {
      const onSuccess = (res) => {
        const data = res.data.data;
        this.case_details = data;

        if (data.university) {
          this.university_list.push(data.university);
        }

        this.form = {
          student_name: data.student_name,
          student_email: data.student_email,

          service_type: this.$_.find(this.service_types, {
            key: data.service.type,
          }),
          service: data.service,
          university: data.university ? data.university.id : null,
          education: data.education ? data.education.id : null,
          course: data.course ? data.course.id : null,
          no_of_sets: data.no_of_sets,
          no_of_documents: data.no_of_documents,
          send_to_eca: data.send_to_eca,
          eca_reference_number: data.eca_reference_number,
          document_type: data.document_type,
          delivery_type: data.delivery_type,

          // pricing
          university_cost: data.university_cost,
          university_cost_per_document: data.university_cost_per_document,
          university_additional_cost: data.university_additional_cost,
          processing_cost: data.processing_cost,
          processing_cost_per_copy: data.processing_cost_per_copy,
          processing_cost_per_document: data.processing_cost_per_document,
          processing_additional_cost: data.processing_additional_cost,
          shipping_cost: data.shipping_cost,
          gst_percent: data.gst_percent,
          discount_percent: data.discount_percent,

          // tat
          internal_tat_days: data.internal_tat_days,
          client_tat_days: data.client_tat_days,

          remarks: data.remarks,
        };

        if (data.city) {
          this.form.city = data.city;
          this.city_list.push(data.city);
        }

        if (data.state) {
          this.form.state = data.state;
          this.state_list.push(data.state);
        }

        this.form.eca = data.eca.map((eca) => eca.id);
      };

      const params = {
        case_id: this.$route.params.id,
      };

      return this.$request(this.$keys.GET, this.$urls.case.detail, {
        params,
        onSuccess,
      });
    },

    onStateChange() {
      this.form.city = null;

      this.getUniversityList();
      this.form.university = null;
    },

    onUniversityChange() {
      this.form.state = null;
      this.form.city = null;

      const selectedUniversity = _.find(this.university_list, {
        id: this.form.university,
      });

      if (selectedUniversity)
        if (selectedUniversity.city) {
          this.form.city = selectedUniversity.city;
          if (selectedUniversity.city.state)
            this.form.state = selectedUniversity.city.state;
        }
    },
  },
};
</script>
<style scoped>
.main-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.01em;
}
.main-sub-title {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.01em;
  opacity: 0.6;
}
.info-section {
  min-height: 70vh;
  border: 1px solid rgba(51, 51, 51, 0.5);
  border-radius: 5px;
}
.sub-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}
.section-name {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  opacity: 0.8;
}
.amount-section {
  background: rgba(51, 51, 51, 0.7);
  border-radius: 5px;
  /* height: 52.8px;   */
}
.documents-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  opacity: 0.8;
}
.v-messages__message {
  color: blue !important;
}
.hint-title {
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  position: absolute;
}
.hint-subtitle {
  position: relative;
  top: -20px;
  left: 12px;
}
</style>
